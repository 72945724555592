import * as React from "react"
import { Link, graphql } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { v4 as uuidv4 } from "uuid"
import { FooterRecCard } from "../components/resources"
import { useState } from "react"
import {
  Form,
  FormGroup,
  FormControl,
  Button,
} from "react-bootstrap"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { errorFindSTA } from "../functions/errorFind"

const breadData = [
  {
    id: 1,
    ink: "/",
    label: "HOME",
  },
  {
    id: 3,
    ink: "/secure-the-high-ground/",
    label: "Do You Have the High Ground",
  },
]

const resourcesList = [
  {
    title: "VigilantMEDR ",
    subtitle: "Managed Endpoint Detection and Response",
    LinkUr: "https://sp1.sdcdn.app/pdf/MEDR-Solution-Brief.pdf",
    cat: "SOLUTION BRIEF",
  },
  {
    title: "VigilantMNDR ",
    subtitle: "Managed Network Detection and Response",
    LinkUr: "https://sp1.sdcdn.app/pdf/MNDR-Solution-Brief.pdf",
    cat: "SOLUTION BRIEF",
  },
  {
    title: "Vigilant365™",
    subtitle: "Enhanced Microsoft 365 Detection",
    LinkUr: "https://sp1.sdcdn.app/pdf/V365-Solution-Brief.pdf",
    cat: "SOLUTION BRIEF",
  },
]

const GaForm = ({ data }) => {
  const [errors, setErrors] = useState({})
  const [subscribe, setSubscribe] = useState(false)
  const [fields, setFields] = useState({
    firstName: "",
    lastName: "",
    job: "",
    company: "",
    email: "",
    subscribe: "",
  })
  function submit(e) {
    e.preventDefault()
    const openErrors = errorFindSTA(fields)
    if (Object.keys(openErrors).length > 0) {
      setErrors(openErrors)
    } else {
      var xhr = new XMLHttpRequest()
      var url =
        "https://api.hsforms.com/submissions/v3/integration/submit/7763866/1a535e0b-965b-475e-9762-3631eaa89f01"

      var data = {
        fields: [
          {
            name: "firstname",
            value: fields.firstName,
          },
          {
            name: "lastname",
            value: fields.lastName,
          },
          {
            name: "jobtitle",
            value: fields.job,
          },
          {
            name: "company",
            value: fields.company,
          },
          {
            name: "email",
            value: fields.email,
          },
          {
            name: "subscribe",
            value: subscribe ? true : false,
          },
        ],
      }

      var final_data = JSON.stringify(data)

      xhr.open("POST", url)
      // Sets the value of the 'Content-Type' HTTP request headers to 'application/json'
      xhr.setRequestHeader("Content-Type", "application/json")

      xhr.onreadystatechange = function () {
        if (xhr.readyState === 4 && xhr.status === 200) {
          window.location.href = "/us/ga-download"
        } else if (xhr.readyState === 4 && xhr.status === 400) {
          console.log(xhr.responseText) // Returns a 400 error the submission is rejected.
        } else if (xhr.readyState === 4 && xhr.status === 403) {
          console.log(xhr.responseText) // Returns a 403 error if the portal isn't allowed to post submissions.
        } else if (xhr.readyState === 4 && xhr.status === 404) {
          console.log(xhr.responseText) //Returns a 404 error if the formGuid isn't found
        }
      }

      // Sends the request

      xhr.send(final_data)
    }
  }

  return (
    <Layout>
      <Seo title="Gated Asset Form" />
      <div className="breadcrumbs">
        <nav className="container-fluid p-sides-med">
          <ul>
            {breadData.map(breadcrumb => (
              <li key={breadcrumb.id}>
                <Link to={breadcrumb.ink}>{breadcrumb.label}</Link>
              </li>
            ))}
          </ul>
        </nav>
      </div>
      <div className="grid--bg --gated-assets ">
        <section className="section-title-top--with-form">
          <div className="container">
            <div className="row">
              <div className="col-sm-6">
                <div className="side-binder pb-5 pt-5">
                  <h3>
                    8 QUESTIONS TO DETERMINE IF <br /> YOU HAVE SECURED THE HIGH{" "}
                    <br />
                    GROUND
                  </h3>
                </div>
                <StaticImage
                  src="../images/landing/Vigilant_HigherGroundCover-for-Web.png"
                  quality={95}
                  formats={["AUTO", "WEBP"]}
                  width={350}
                  alt="8 Questions"
                  className="text-center img-fluid pb-5"
                />
                <p className="pb-5 mt-5">
                  We created this report for you so that your company can have a
                  battleplan for essential cyber security. Sun Tzu taught to
                  always have the “High Ground” and this article is designed to
                  get you there. Inside these pages you will take a probing
                  8-Question self-evaluation and receive dozens of actionable
                  insights and suggestions.
                </p>
              </div>
              <div className="col-sm-6 pt-5">
                <div className="container">
                  <div className="box">
                    <Form>
                      <Form.Group className="mb-3" controlId="formFirstName">
                        <Form.Control
                          value={fields.firstName}
                          className="bg-white"
                          type="fName"
                          placeholder="First Name"
                          onChange={e =>
                            setFields({ ...fields, firstName: e.target.value })
                          }
                        />

                        <span>{errors.firstName}</span>
                      </Form.Group>
                      <FormGroup className="mb-3" controlId="formLastName">
                        <FormControl
                          value={fields.lastName}
                          className="bg-white"
                          type="lName"
                          placeholder="Last Name"
                          onChange={e =>
                            setFields({ ...fields, lastName: e.target.value })
                          }
                        />
                        <span type="invalid">{errors.lastName}</span>
                      </FormGroup>
                      <Form.Group className="mb-3" controlId="formJobTitle">
                        <Form.Control
                          value={fields.job}
                          className="bg-white"
                          type="job"
                          placeholder="Job Title"
                          onChange={e =>
                            setFields({ ...fields, job: e.target.value })
                          }
                        />

                        <span>{errors.job}</span>
                      </Form.Group>
                      <FormGroup className="mb-3" controlId="formCompany">
                        <FormControl
                          value={fields.company}
                          className="bg-white"
                          type="jobTitle"
                          placeholder="Company"
                          onChange={e =>
                            setFields({ ...fields, company: e.target.value })
                          }
                        />
                        <span type="invalid">{errors.company}</span>
                      </FormGroup>
                      <FormGroup className="mb-3" controlId="formBasicEmail">
                        <FormControl
                          value={fields.email}
                          className="bg-white"
                          type="email"
                          placeholder="Business Email"
                          onChange={e =>
                            setFields({ ...fields, email: e.target.value })
                          }
                        />
                        <span type="invalid">{errors.email}</span>
                      </FormGroup>
                      <FormGroup>
                        <Form.Check
                          id="custom-switch"
                          label="Sign up to receive updates from Vigilant about our services, news and events. You can unsubscribe anytime."
                          onClick={() => setSubscribe(!subscribe)}
                        />
                      </FormGroup>
                      <FormGroup>
                        <Button
                          style={{
                            width: "100%",
                            backgroundColor: "transparent",
                          }}
                          className="nav-section--cta-btn"
                          variant="primary"
                          type="submit"
                          onClick={submit}
                        >
                          <h4>DOWNLOAD NOW</h4>
                        </Button>
                        <p className="pt-5 white">
                          <em>
                            Vigilant is committed to ensuring the security and
                            protection of the personal information that we
                            process, and to provide a compliant and consistent
                            approach to data protection. If you have any
                            questions related to our privacy policies, please
                            contact legal@vigilantnow.com
                          </em>
                        </p>
                      </FormGroup>
                    </Form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="section-resources">
          <div className="container">
            <div className="row">
              <div className="section-col-1">
                <h3>RELATED RESOURCES</h3>
              </div>
              <div className="section-col-2">
                <ul className="resources-list">
                  {resourcesList.map(data => {
                    return (
                      <FooterRecCard
                        altTxt={data.title}
                        title={data.title}
                        subTitle={data.subtitle}
                        recCat={data.cat}
                        LinkUrl={data.LinkUr}
                        key={uuidv4()}
                      />
                    )
                  })}
                </ul>
              </div>
            </div>
          </div>
        </section>
      </div>
      <div className="breadcrumbs">
        <nav className="container-fluid p-sides-med">
          <ul>
            {breadData.map(breadcrumb => (
              <li key={breadcrumb.id}>
                <Link to={breadcrumb.ink}>{breadcrumb.label}</Link>
              </li>
            ))}
          </ul>
        </nav>
      </div>
    </Layout>
  )
}
export const query = graphql`
  query MyQuery {
    allFile(filter: { extension: { eq: "pdf" } }) {
      edges {
        node {
          publicURL
          name
        }
      }
    }
  }
`
export default GaForm
